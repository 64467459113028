import * as React from 'react'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import { ErrorBoundary } from 'react-error-boundary'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { NextIntlProvider } from 'next-intl'
import { ErrorFallback } from '@/components/reusables/ErrorFallback'
import { NotificationToaster } from '@/utils/notificationToaster'
import theme from '../styles/theme'
import createEmotionCache from '../styles/createEmotionCache'
import '../styles/global.css'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()
interface MyAppProps extends AppProps {
  emotionCache: EmotionCache
}

const tagManagerArgs = {
  // TODO: make a public NEXT env for browser
  gtmId: 'GTM-W7ZDWS3',
}

if (process.browser) {
  console.log(process.env.GTM_ID)
  TagManager.initialize(tagManagerArgs)
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <NextIntlProvider messages={pageProps.messages}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component {...pageProps} />{' '}
          </ErrorBoundary>
          <NotificationToaster/>
        </NextIntlProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default MyApp
